import * as React from "react"
import { graphql } from "gatsby"
import { Header, Layout } from '../components'
import { TypePage } from "../containers/Main";

class Page extends React.Component {
  render() {
    const {
      data: {
        prismicPages: {
          uid,
          data: {
            title,
            description,
            body
          }
        }
      }
    } = this.props;

    return (
      <Layout
        context={{
          title: title.text,
          description: description.text,
          slug: `/articles/${uid}/`,
        }}
      >
        <TypePage>
          <Header location={this.props.location} />
          <div style={{ paddingTop: '3.2rem' }}>
            <h1>{title.text}</h1>
            <p dangerouslySetInnerHTML={{ __html: description.html }}></p>
            {
              body.length > 0 && body.map((item, index) => {
                return <Slice
                  data={item}
                  key={`page-slice-${index}`}
                />
              })
            }
          </div>
        </TypePage>
      </Layout>
    )
  }
}


const Slice = ({ data: { primary, items, slice_type } }) => {
  
  switch (slice_type) {
    case 'image___texte':
      return (
        <div>
          <img src={primary.add_image.localFile.publicURL} alt={"photo " + primary.add_text.text} />
          <p dangerouslySetInnerHTML={{ __html: primary.add_text.html }}></p>
        </div>
      )

    case 'videos':
      return (
        <div>
          {
            (items && items.length > 0) && items.map((item, index) => {
              return (
                <p 
                  dangerouslySetInnerHTML={{ __html: item.caption.html }} 
                  key={`slice-videos-${index}`}
                ></p>
              )
            })
          }
        </div>
      )

    case 'photos':
      return (
        <div>
          {
            (items && items.length > 0) && items.map((item, index) => {
              return (
                <React.Fragment key={`slice-photos-${index}`}>
                  <img src={item.photo.localFile.publicURL} alt={"photo " + item.legend.html} />
                  <p dangerouslySetInnerHTML={{ __html: item.legend.html }}></p>
                </React.Fragment>
              )
            })
          }
        </div>
      )
  
    default:
      return <div></div>
  }
}

export default Page;
export const pageQuery = graphql`
  query currentPage($slug: String!) {
    prismicPages(uid: { eq: $slug }) {
      uid
      data {
        archive
        description {
          html
        }
        status
        title {
          text
        }

        body {
          ... on PrismicPagesDataBodyImageTexte {
            id
            slice_type
            slice_label
            primary {
              add_image {
                localFile {
                  publicURL
                }
              }
              add_text {
                html
                text
              }
            }
          }
          ... on PrismicPagesDataBodyPhotos {
            id
            slice_type
            slice_label
            items {
              legend {
                html
                text
              }
              photo {
                localFile {
                  publicURL
                }
              }
            }
          }
          ... on PrismicPagesDataBodyVideos {
            id
            slice_type
            slice_label
            items {
              caption {
                html
                text
              }
              video {
                embed_url
              }
            }
          }
        }
      }
    }
  }
`